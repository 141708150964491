<template>
  <div class="goods">
    <van-tree-select
      v-model:main-active-index="activeIndex"
      height="300vw"
      :items="category"
    >
      <template #content>
        <div v-for="item in node" :key="item.key">
          <van-grid v-if="activeIndex === item.key" :column-num="3">
            <van-grid-item
              v-for="vitem in item.data"
              :key="vitem.id"
              :text="vitem.title"
              :to="'/goods?id=' + vitem.id"
            >
              <van-image :src="vitem.image" />
              <span>{{ vitem.title }}</span>
            </van-grid-item>
          </van-grid>
        </div>
      </template>
    </van-tree-select>
    <siteabbar :idx="1" />
  </div>
</template>

<script>
import Siteabbar from "../components/Sitetabbar";
import { TreeSelect, Grid, GridItem } from "vant";
import { ref } from "vue";
import { getBarData } from "@/api/query";

export default {
  components: {
    [Siteabbar.name]: Siteabbar,
    [TreeSelect.name]: TreeSelect,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },
  setup() {
    const activeIndex = ref(0);
    return {
      activeIndex,
    };
  },
  data() {
    return {
      category: [],
      node: [],
      items: [],
    };
  },
  created() {
    // Mock: get all routes and roles list from server
    this.getIndexData();
  },

  methods: {
    async getIndexData() {
      const res = await getBarData();
      this.category = res.data.category;
      this.node = res.data.node;
    },
    goto(id) {
      // this.$router.push("/bar-clist?catid=" + id);
      this.$router.push("/goods?id=" + id);
    },
  },
};
</script>

<style lang="less">
.goods {
  padding-bottom: 50px;

  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }

  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;

    .van-cell__value {
      color: #999;
    }
  }

  &-tag {
    margin-left: 5px;
  }
  .goods-swipe img {
    max-height: 500px;
  }
}
.van-tree-select__content {
  flex: 3;
}
</style>
